import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import SecondaryLanding from "../../../layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import FaqAccordion from "../../../components/faq/faq-accordion";

import openAccountIcon from "../../../../static/images/icons/open-an-account-96.svg";
import applePayIcon from "../../../../static/images/icons/apple-pay-badge.svg";
import googlePayIcon from "../../../../static/images/icons/google-pay-badge.svg";
import samsungPayIcon from "../../../../static/images/icons/samsung-pay-badge.svg";
import debitCardIcon from "../../../../static/images/icons/debit-card-icon-96.svg";
import downloadIcon from "../../../../static/images/icons/download-app-icon-96.svg";
import digitalWalletIcon from "../../../../static/images/icons/digital-wallet-icon-96.svg";

const DigitalDebitCards = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/personal-banking/debit-cards/digital-debit-cards/hero-digital-debit-card-04282023-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/personal-banking/debit-cards/digital-debit-cards/hero-digital-debit-card-04282023-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/personal-banking/debit-cards/digital-debit-cards/hero-digital-debit-card-04282023-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/personal-banking/debit-cards/digital-debit-cards/hero-digital-debit-card-04282023-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/personal-banking/debit-cards/digital-debit-cards/hero-digital-debit-card-04282023-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/personal-banking/debit-cards/digital-debit-cards/hero-digital-debit-card-04282023-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/personal-banking/debit-cards/digital-debit-cards/hero-digital-debit-card-04282023-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);
  const heroChevronData = {
    id: "digital-debit-cards-hero",
    ...getHeroImgVariables(imgData),
    altText: "Woman paying for dessert with digital debit card mobile pay.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Digital Cards in Your Digital Wallet"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white my-3",
            text: "Open an Account",
            url: "/open-an-account"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-2",
            class: "btn-light",
            text: "Apply for a Credit Card",
            url: "https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-cash-back-rewards-visa-credit-card&v_id=null&_st=1691605568646&ga_id=336159027.1686195739&_gl=1*117bhbu*_ga*MzM2MTU5MDI3LjE2ODYxOTU3Mzk.*_ga_D84532J1C1*MTY5MTYwNTU2OS43OS4xLjE2OTE2MDU2MzAuMC4wLjA."
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      title: "Debit Cards",
      url: "/personal-banking/debit-cards"
    },
    {
      id: 3,
      title: "Digital Debit and Credit Cards",
      active: true
    }
  ];

  const SEOData = {
    title: "Digital Debit and Credit Cards",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Digital Debit and Credit Cards"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "A digital debit and credit card easily connects your personal bank account to your digital wallet for instant, contactless, and secure shopping wherever you go."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/debit-cards/digital-debit-card"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-digital-debit-card-04282023.jpg"
      }
    ]
  };

  const faqAccordionData = {
    id: "digital-cards-faq-accordion",
    faqCategoryNames: ["Digital Wallet"]
  };

  return (
    <SecondaryLanding>
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <SEO {...SEOData} />
      <section className="container">
        <div className="row mb-4">
          <div className="col-lg-8">
            <h1>Digital Debit and Credit Cards</h1>
            <h4>
              There are several types of contactless payment options that eliminate the need to pay with cash or swipe
              your credit or debit card in a machine. Instead, pay with a quick tap of your chip-enabled card, or use a
              payment-enabled phone or wearable device at many checkout counters.
            </h4>
          </div>
        </div>

        <h2>How to Set Up Your Digital Wallet</h2>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <div className="d-flex">
              <div className="col-auto p-0 mr-0 mr-sm-4">
                <img src={openAccountIcon} alt="Open Account Icon" />
              </div>
              <div className="col pr-0">
                <h4 className="text-green-60">Open an account</h4>
                <p>
                  Whether you need a{" "}
                  <Link to="/personal-banking/checking-account" id="digital-wallet-personal-banking-link">
                    personal checking account
                  </Link>{" "}
                  or to apply for a{" "}
                  <Link to="/personal-banking/credit-cards" id="digital-wallet-cc-link">
                    credit card
                  </Link>
                  , you can do so online in just minutes.
                </p>
                <div className="d-md-flex flex-column flex-md-row d-none">
                  <div className="mb-4 mb-xl-0 mr-md-3">
                    <Link
                      className="btn btn-primary no-min-width w-100 w-md-auto"
                      id="digital-wallet-open-account-cta"
                      to="/open-an-account"
                    >
                      Open an Account
                    </Link>
                  </div>
                  <div>
                    <a
                      href="https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-cash-back-rewards-visa-credit-card&v_id=null&_st=1691605568646&ga_id=336159027.1686195739&_gl=1*117bhbu*_ga*MzM2MTU5MDI3LjE2ODYxOTU3Mzk.*_ga_D84532J1C1*MTY5MTYwNTU2OS43OS4xLjE2OTE2MDU2MzAuMC4wLjA."
                      id="digital-wallet-apply-cc-cta"
                      className="btn btn-link no-min-width w-100 w-md-auto"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Apply for a Credit Card
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block d-md-none">
              <div className="mb-4">
                <Link className="btn btn-primary w-100" id="digital-wallet-open-account-cta" to="/open-an-account">
                  Open an Account
                </Link>
              </div>
              <div>
                <a
                  href="https://creditcards.wafdbank.com/apply/?store=wafdbank&product=wafd-bank-cash-back-rewards-visa-credit-card&v_id=null&_st=1691605568646&ga_id=336159027.1686195739&_gl=1*117bhbu*_ga*MzM2MTU5MDI3LjE2ODYxOTU3Mzk.*_ga_D84532J1C1*MTY5MTYwNTU2OS43OS4xLjE2OTE2MDU2MzAuMC4wLjA."
                  id="digital-wallet-apply-cc-cta"
                  className="btn btn-link w-100"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Apply for a Credit Card
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="d-flex">
              <div className="col-auto p-0 mr-0 mr-sm-4">
                <img src={debitCardIcon} alt="Debit Card icon" />
              </div>
              <div className="col pr-0">
                <h4 className="text-green-60">Get your debit or credit card</h4>
                <p>
                  When you sign up for a personal checking account or apply for a credit card, you will receive your
                  card in the mail.
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 mb-4">
            <div className="d-flex">
              <div className="col-auto p-0 mr-0 mr-sm-4">
                <img src={downloadIcon} alt="Download digital wallet icon" />
              </div>
              <div className="col pr-0">
                <h4 className="text-green-60">Download a digital wallet</h4>
                <p>
                  WaFd Bank's digital debit cards and credit cards can be used through{" "}
                  <a
                    href="https://www.apple.com/apple-pay/"
                    id="digital-wallet-apple-pay-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apple Pay
                  </a>
                  ,{" "}
                  <a
                    href="https://pay.google.com/about/"
                    id="digital-wallet-google-pay-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Pay
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.samsung.com/us/samsung-pay/"
                    id="digital-wallet-samsung-pay-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Samsung Pay
                  </a>
                  .{" "}
                  <div className="d-sm-flex d-none mt-3">
                    <div className="position-relative mr-2">
                      <a
                        href="https://www.apple.com/apple-pay/"
                        id="digital-wallet-apple-pay-badge"
                        className="stretched-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="sr-only">Apple Pay icon</span>
                      </a>
                      <img src={applePayIcon} alt="Apple Pay badge" />
                    </div>
                    <div className="position-relative mr-2">
                      <a
                        href="https://pay.google.com/about/"
                        id="digital-wallet-google-pay-badge"
                        className="stretched-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="sr-only">Google Pay icon</span>
                      </a>
                      <img src={googlePayIcon} alt="Google Pay badge" />
                    </div>
                    <div className="position-relative">
                      <a
                        href="https://www.samsung.com/us/samsung-pay/"
                        id="digital-wallet-samsung-pay-badge"
                        className="stretched-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="sr-only">Samsung Pay icon</span>
                      </a>
                      <img src={samsungPayIcon} alt="Samsung Pay badge" />
                    </div>
                  </div>
                </p>
              </div>
            </div>

            <div className="d-flex mt-3 d-sm-none">
              <div className="position-relative mr-2">
                <a
                  href="https://www.apple.com/apple-pay/"
                  id="digital-wallet-apple-pay-badge"
                  className="stretched-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">Apple Pay icon</span>
                </a>
                <img src={applePayIcon} alt="Apple Pay badge" />
              </div>
              <div className="position-relative mr-2">
                <a
                  href="https://pay.google.com/about/"
                  id="digital-wallet-google-pay-badge"
                  className="stretched-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">Google Pay icon</span>
                </a>
                <img src={googlePayIcon} alt="Google Pay badge" />
              </div>
              <div className="position-relative">
                <a
                  href="https://www.samsung.com/us/samsung-pay/"
                  id="digital-wallet-samsung-pay-badge"
                  className="stretched-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">Samsung Pay icon</span>
                </a>
                <img src={samsungPayIcon} alt="Samsung Pay badge" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-4">
            <div className="d-flex">
              <div className="col-auto p-0 mr-0 mr-sm-4">
                <img src={digitalWalletIcon} alt="Digital Wallet Icon" />
              </div>
              <div className="col pr-0">
                <h4 className="text-green-60">Add your card to your digital wallet</h4>
                <p>
                  Add your card's number and security code to your digital wallet on your digital wallet app. It's that
                  easy!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default DigitalDebitCards;
